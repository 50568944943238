import React, { useEffect, useRef, useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Glass from "./Concept/Glass";
import { imgDir } from "../utils";
import ConceptIndicator from "./Concept/Indicator";
import Typewriting from "./Concept/Typewriting";
import { Link } from "react-router-dom";
import { useReloadFonts } from "../hooks/useReloadFonts";
import { useWindowSize } from "../hooks/useWindowSize";
import { BrowserView, MobileView } from "react-device-detect";
import Typewriter from 'typewriter-effect';

export default function Concept(props) {
    const [windowWidth, windowHeight] = useWindowSize()
    const reloadFonts = useReloadFonts()

    const fullpageRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(0)
    const [prevPage, setPrevPage] = useState(0)

    const handleScrolling = (allow) => {
        fullpageRef.current.fullpageApi.setAllowScrolling(allow)
    }
    const finishTypewriting = () => {
        // fullpageRef.current.fullpageApi.setAllowScrolling(false)
        handleScrolling(true)

    }
    const finishDeleting = () => {
    }

    const [typewriter, setTypewriter] = useState(null);
    useEffect(() => {
        if (typewriter) {
            typewriter
            .changeCursor('|')
            .typeString('景色に輪郭をあたえる<br />　影のようなデザイン')
            .changeCursor('　')
            .start();
        }
    }, [typewriter])
 
    return (
        <>
            <div className={`fixed z-0 top-0 left-0 w-screen overflow-hidden transition-opacity duration-[2s] ${currentPage===0? 'opacity-100': 'opacity-0'}`} style={{height: windowHeight}}>
                <h1 className="absolute md:!top-[calc(50vh-200px)] left-[calc(50vw-211px)] md:left-[calc(50vw-687px)] text-transparent text-blur-sm md:text-blur text-[80px] md:text-[260px] tracking-widest cormorant opacity-40" style={{top: windowHeight/2-60}}>CONCEPT</h1>
            </div>
            <Typewriting currentPage={currentPage} prevPage={prevPage} finishTypewriting={finishTypewriting} finishDeleting={finishDeleting}/>
            <ConceptIndicator currentPage={currentPage}/>
            <ReactFullpage
                licenseKey={'V49L9-WPF3I-5K027-DJPEI-CKOWN'}
                ref={fullpageRef}
                scrollingSpeed = {2000} /* Options here */
                onLeave={(origin, destination, direction) => {
                    setPrevPage(origin.index)
                    setCurrentPage(destination.index)
                    if (destination.index < 4 && destination.index > 0) {
                        handleScrolling(false)
                    } else if (destination.index === 4 && direction === 'down') {
                        handleScrolling(false)

                    }
                }}
                afterRender={() => {
                // oninitialized={(origin, destination, direction) => {
                    reloadFonts()

                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <section className="section">
                                <div className="px-8 relative">
                                    <h2 className="text-center text-2xl leading-10">
                                        <Typewriter
                                            onInit={(typewriter) => {
                                                setTypewriter(typewriter);
                                            }}
                                            options = {{
                                                looop: false,
                                                cursor: '　',
                                                deleteSpeed: 10,
                                                delay: 60,
                                            }}
                                        />
                                    </h2>
                                </div>
                            </section>
                            <section className="section">
                            </section>
                            <section className="section">
                                <div className="max-w-[880px] mx-auto">
                                </div>
                            </section>
                            <section className="section">
                                <div className="max-w-[880px] mx-auto">
                                </div>
                            </section>
                            <section className="section">
                                <div className="max-w-[880px] mx-auto">
                                </div>
                            </section>
                            <section className="section">
                                <div className="max-w-[880px] mx-auto">
                                </div>
                            </section>
                            <section className="section">
                                <div className="max-w-[880px] mx-auto">
                                </div>
                            </section>
                            <section className="section">
                                <div className="max-w-[880px] mx-auto flex justify-center">
                                    <p className={`text-sm md:text-lg !leading-8 lg:!leading-10 transition-opacity duration-1000 mb-28 ${currentPage>4? 'opacity-100': 'opacity-0'}`}>
                                        影は決して主役ではありません。<br />
                                        しかし、迷った時に方向性を示し、<br />
                                        見えていなかった新たな発見を生み、<br />
                                        今あるものを豊かにしてくれる存在です。<br />
                                        <br />
                                        六景は素材の持つ価値を引き出す<br />
                                        影のようなデザインを目指します。
                                    </p>
                                </div>
                                <div className="absolute bottom-12 md:bottom-16 left-0 w-full">
                                    <h4 className="text-2xl text-center font-medium tracking-wider">ROKKEI’S PROJECTS</h4>
                                    <p className="mt-4  text-center text-xs leading-5 tracking-wider">クライアントワーク以外に社会へ問いを投げかける<br className="md:hidden"/>企画やブランドの運営をおこなっています</p>
                                    <div className="flex justify-center mt-2 md:mt-4">
                                        <svg className={`w-1.5 md:w-2 h-1.5 md:h-2 `}  viewBox="0 0 24 24">
                                            <polygon fill="currentColor" stroke="none" points="0,0 12,22 24,0" />
                                        </svg>
                                    </div>
                                </div>
                            </section>
                            <section className="section" style={{minHeight: windowHeight}}>
                                <div className="bg-[#E8E8E8] pt-32 pb-12 md:pb-32 px-5 md:px-0 ">
                                    <div className="max-w-[970px] mx-auto relative">
                                        <h5 className="md:hidden absolute -right-2 top-[216px] rotate-90 origin-top-right text-xl tracking-wider">ROKKEI’S PROJECTS</h5>
                                        <div className=" grid grid-cols-1 md:grid-cols-2 gap-12 px-8">
                                            <div className="relative group">
                                                <img className="w-full h-auto" src={imgDir() + '/concept/no_mix.jpg'} />
                                                <div className="absolute inset-0 flex justify-center items-center">
                                                    <img className="h-36 md:h-auto" src={imgDir() + '/concept/typo-no_mix.svg'} />
                                                </div>
                                                <MobileView>
                                                    <a href="https://mazerunakiken.net/" target="_blank" className="absolute block inset-0 text-white/0">
                                                        混ぜるな危険
                                                    </a>
                                                </MobileView>
                                                <BrowserView>
                                                    <div className="flex absolute inset-0 bg-black/70 flex-col justify-center items-center space-y-6 transition-opacity duration-500 opacity-0 group-hover:opacity-100">
                                                        <h5 className="text-white text-2xl">混ぜるな危険</h5>
                                                        <p className="text-white text-center text-xs leading-7">香りで、世界の解像度は上がる。<br />混ぜるな危険は、香りと接続してこなかった「何か」を<br />繋げていくプロジェクトチーム。</p>
                                                        <a href="https://mazerunakiken.net/" target="_blank" className="border-1.5 border-white py-2 w-60 bg-white rounded-full text-black text-center text-sm transition-colors hover:bg-white/90">MORE</a>
                                                    </div>
                                                </BrowserView>
                                            </div>
                                            <div className="relative group">
                                                <img className="w-full h-auto" src={imgDir() + '/concept/hunch.jpg'} />
                                                <div className="absolute inset-0 flex justify-center items-center">
                                                    <img className="w-32 md:w-auto" src={imgDir() + '/concept/typo-hunch.svg'} />
                                                </div>
                                                <MobileView>
                                                    <a href="https://www.instagram.com/hunch.jewelry/" target="_blank" className="absolute block inset-0 text-white/0">
                                                        hunch
                                                    </a>
                                                </MobileView>
                                                <BrowserView>
                                                    <div className="absolute inset-0 bg-black/70 flex flex-col justify-center items-center space-y-6 transition-opacity duration-500 opacity-0 group-hover:opacity-100">
                                                        <h5 className="text-white text-2xl">hunch</h5>
                                                        <p className="text-white text-center text-xs leading-7">「知ってるようで、知らないもの、と。」を<br />コンセプトにした、虫と共創する<br />ジュエリーブランド。</p>
                                                        <a href="https://www.instagram.com/hunch.jewelry/" target="_blank" className="border-1.5 border-white py-2 w-60 bg-white rounded-full text-black text-center text-sm transition-colors hover:bg-white/90">MORE</a>
                                                    </div>
                                                </BrowserView>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="flex flex-col md:flex-row justify-center items-center space-y-5 md:space-y-0 md:space-x-10 min-h-[200px] py-6 lg:py-0">
                                    <Link to="/" className="border-1.5 text-center border-black rounded-full py-2 block w-60 text-xs md:text-sm hover:bg-black hover:text-white jost tracking-widest">CREATIVE</Link>
                                    <Link to="/company" className="border-1.5 text-center border-black rounded-full py-2 block w-60 text-xs md:text-sm hover:bg-black hover:text-white jost tracking-widest">COMPANY</Link>
                                    <Link to="/contact" className="border-1.5 text-center border-black rounded-full py-2 block w-60 text-xs md:text-sm hover:bg-black hover:text-white jost tracking-widest">CONTACT</Link>
                                </div>
                                <div className="h-9">
                                </div>
                            </section>

                        </ReactFullpage.Wrapper>
                    );
                }}
            />
            <Glass currentPage={currentPage} prevPage={prevPage} />
        </>
    )
}